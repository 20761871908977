<template>
  <div class="resources fade-up">
    <div class="dashboard-table" v-loading="isLoading">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedResource()"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <h2>Категориялар</h2>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="dialogNewVisible = true"
          >
            Янги қушиш
          </el-button>
        </div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          style="width: 100%"
          :default-sort="{ prop: 'created_at', order: 'descending' }"
          :data="categories"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="created_at"
            label="Яратилган вақти"
            width="180"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.published_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column prop="name_uz" label="Категория номи">
          </el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="editCategory(scope.row)"
              />
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteResource(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      class="dialog-sm"
      width="300px"
      title="Янги категория"
      :visible.sync="dialogNewVisible"
    >
      <el-form :model="addForm">
        <el-form-item label="Категория номи (O'zb)">
          <el-input v-model="addForm.name_oz"></el-input>
        </el-form-item>
        <el-form-item label="Категория номи (Узб)">
          <el-input v-model="addForm.name_uz"></el-input>
        </el-form-item>
        <el-form-item label="Категория номи (Рус)">
          <el-input v-model="addForm.name_ru"></el-input>
        </el-form-item>
        <el-form-item label="Slug">
          <el-input v-model="addForm.slug"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNewVisible = false">Бекор қилиш</el-button>
        <el-button
          :disabled="
            !addForm.name_uz ||
              !addForm.name_oz ||
              !addForm.name_ru ||
              !addForm.slug
          "
          type="primary"
          @click="addCategory"
        >
          Қушиш
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialog-sm"
      width="300px"
      title="Категорияни тахрирлаш"
      :visible.sync="dialogEditVisible"
    >
      <el-form :model="editForm">
        <el-form-item label="Категория номи (O'zb)">
          <el-input v-model="editForm.name_oz"></el-input>
        </el-form-item>
        <el-form-item label="Категория номи (Узб)">
          <el-input v-model="editForm.name_uz"></el-input>
        </el-form-item>
        <el-form-item label="Категория номи (Рус)">
          <el-input v-model="editForm.name_ru"></el-input>
        </el-form-item>
        <el-form-item label="Slug">
          <el-input v-model="editForm.slug"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">Бекор қилиш</el-button>
        <el-button
          type="primary"
          :disabled="
            !editForm.name_uz ||
              !editForm.name_oz ||
              !editForm.name_ru ||
              !editForm.slug
          "
          @click="saveCategory"
        >
          Саклаш
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    dialogNewVisible: false,
    dialogEditVisible: false,
    addForm: {
      name_uz: "",
      name_ru: "",
      name_oz: "",
      slug: ""
    },
    editForm: {
      name_uz: "",
      name_ru: "",
      name_oz: "",
      slug: ""
    },
    multipleSelection: []
  }),
  computed: {
    categories() {
      return this.$store.state.categoryModule.categories;
    }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    this.$store.dispatch("fetchCategories").finally(() => loading.close());
  },
  methods: {
    deleteResource(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteCategory", row.id)
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteSelectedResource() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteCategory", r.id);
            })
          )
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    addCategory() {
      this.isLoading = true;
      this.dialogNewVisible = false;
      this.$store
        .dispatch("addCategory", this.addForm)
        .then(() => {
          this.$message({
            type: "success",
            message: "Қушилди"
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "Хато!"
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.addForm.name_uz = "";
          this.addForm.name_ru = "";
          this.addForm.name_oz = "";
          this.addForm.slug = "";
        });
    },
    editCategory(item) {
      this.editForm = { ...item };
      this.dialogEditVisible = true;
    },
    saveCategory() {
      this.isLoading = true;
      this.dialogEditVisible = false;
      this.$store
        .dispatch("editCategory", { data: this.editForm, id: this.editForm.id })
        .then(() => {
          this.$message({
            type: "success",
            message: "Сақланди"
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "Хато!"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.resources {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
